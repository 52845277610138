<template>
  <div id="transport-route" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <div class="columns">
          <div class="column is-6 is-offset-3">
            <form @submit.prevent="passes(submit)">
              <SdInput
                type="text"
                rules="required|min:2|max:30"
                label="Name"
                v-model="transportRoute.name"
              />

              <SdInput
                type="text"
                rules="required|min:2|max:30"
                label="Amount"
                v-model="transportRoute.amount"
              />

              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right mt-3"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '../../components/SdInput'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'TransportRoute',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Transport Route',
      skipQuery: true,
      transportRoute: {
        id: null,
        schoolId: null,
        name: '',
        amount: '',
      },
    }
  },
  apollo: {
    transportRoute: {
      query: gql`
        query TransportRouteQuery($id: ID!) {
          transportRoute(id: $id) {
            id
            schoolId
            name
            amount
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
  },
  methods: {
    submit() {
      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation CreateTransportRoute(
                $school_id: Int!
                $name: String!
                $amount: Float!
              ) {
                createTransportRoute(
                  input: { schoolId: $school_id, name: $name, amount: $amount }
                ) {
                  transportRoute {
                    schoolId
                    name
                    amount
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              school_id: this.schoolId,
              name: this.transportRoute.name,
              amount: parseFloat(this.transportRoute.amount),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createTransportRoute.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/transport_routes`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation UpdateTransportRoute(
                $id: Int!
                $name: String!
                $amount: Float!
              ) {
                updateTransportRoute(
                  input: { id: $id, name: $name, amount: $amount }
                ) {
                  transportRoute {
                    id
                    schoolId
                    name
                    amount
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.transportRoute.id),
              name: this.transportRoute.name,
              amount: parseFloat(this.transportRoute.amount),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateTransportRoute.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/transport_routes`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  components: {
    SdInput,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Transport Routes',
        route: `/school/${this.schoolId}/transport_routes`,
      },
    ])
  },
}
</script>
